import axios from "axios";
import { encryptData, decryptData } from "../../crypto";

// exam login api
export const loginApi = async ({ username, password, exam_pin }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/v1/parikshado/student/exam/auth/signin`,
      {
        user: {
          username,
          password,
          exam_pin,
        },
      }
    );

    if (response.data.success === true) {
      localStorage.clear();
      sessionStorage.clear();

      const examToken = encryptData(response.data.token);
      const username = encryptData(response.data.user.full_name);
      const examId = response.data.user.exam_details.id;
      const examInstructions = encryptData(
        response.data.user.exam_details.exam_instruction_en
      );
      const email = encryptData(response.data.user.email);
      const instructionsHindi = encryptData(
        response.data.user.exam_details.exam_instruction_hi
      );
      const exam_name = encryptData(response.data.user.exam_details.exam_name);

      const examInstantResult =
        response.data.user.exam_details.exam_instant_result;
        const starTime=response.data.user.exam_details.start_time.split(':')
      const startDateTime=new Date(response.data.user.exam_details.start_date)
      startDateTime.setHours(starTime[0])
      startDateTime.setMinutes(starTime[1])
      startDateTime.setSeconds(starTime[2].split("+")[0])
      
      sessionStorage.setItem("examInstantResult", examInstantResult);

      sessionStorage.setItem("candidateLoggedinToInstructionsPage", true);

      sessionStorage.setItem("exam_name", exam_name);
      sessionStorage.setItem("startDateTime", startDateTime);

      sessionStorage.setItem("examId", examId);
      sessionStorage.setItem("examToken", examToken);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("userName", username);
      sessionStorage.setItem("examInstructions", examInstructions);
      sessionStorage.setItem("instructionsHindi", instructionsHindi);

      // // login time
      // sessionStorage.setItem("loginTime", new Date().getTime());

      return { success: true };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

// user profile api
export const fetchProfileApi = async () => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/parikshado/student/exam/profile`,
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    return response.data.learner;
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

//exam question api
export const fetchQuestionsData = async (examId) => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/parikshado/student/exam/questions/${examId}`,
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    return response.data.exam;
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

// Save options API
export const saveOptions = async ({
  exam_id,
  subject_id,
  question_id,
  option_id,
  attempt_status,
}) => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/v1/parikshado/student/exam/attempt`,
      {
        attempt: {
          exam_id,
          subject_id,
          question_id,
          option_id,
          attempt_status,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    if (response.data.success === true) {
      return { success: true };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

//get attempted question api
export const submitWarning = async (examId) => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/parikshado/student/exam/check_before_submit/${examId}`,
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    return response.data.submitWarning;
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

// exam submit api
export const submitApi = async ({ examId }) => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  const body = {
    exam_submit: {
      exam_id: examId,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/v1/parikshado/student/exam/submit`,
      body,
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    if (response.data.success === true) {
      const examResult = JSON.stringify(response.data);
      // sessionStorage.setItem("examResult", examResult);

      // localStorage.clear();
      // sessionStorage.clear();

      sessionStorage.setItem("examSubmitted", true);
      sessionStorage.removeItem("examStarted");
      sessionStorage.removeItem("instructionsHindi");
      sessionStorage.removeItem("examInstructions");

      return { success: true, examResult: examResult };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

//checking for question attempsts api
export const fetchAttemptQuestionsData = async (examId) => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/parikshado/student/exam/attempted/questions/${examId}`,
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

//Exam Start API
export const examStartApi = async (examId) => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/v1/b2b/exam/live/${examId}`,
      {}, // This is where you'd put a request body, if needed. Use an empty object if there's no body.
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

//get languages
export const fetchExamLanguages = async (examId) => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/b2b/exam/language/by_exam/${examId}`,
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    return response.data.language;
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};

//exam submitted status check ????
export const isExamSubmittedByHead = async (examId) => {
  const decryptedToken = decryptData(sessionStorage.getItem("examToken"));

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/parikshado/student/exam/auth/submitcheck/${examId}`,
      {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      }
    );

    if (response.data.success === true) {
      localStorage.clear();

      const examResult = JSON.stringify(response.data);
      // sessionStorage.setItem("examResult", examResult);

      sessionStorage.setItem("examSubmitted", true);
      sessionStorage.removeItem("examStarted");
      sessionStorage.removeItem("instructionsHindi");
      sessionStorage.removeItem("examInstructions");

      return { success: true, examResult: examResult };
    }
  } catch (error) {
    console.error("API error:", error);

    // Check if the error has a response with a message
    if (error.response && error.response.data && error.response.data.message) {
      return { success: false, message: error.response.data.message };
    } else {
      // If no specific error message found, return a generic message
      return {
        success: false,
        message: "An error occurred during the API request.",
      };
    }
  }
};
